import {watch}     from "vue";
import {setLocale} from 'yup';
import i18n        from "@/plugins/i18n";

export default {
    install: (app, options) => {
        setLocale({
            mixed : {
                required: i18n.global.t('validation.required'),
            },
            string: {
                //min: 'This must be at least ${min} characters.'
                min: i18n.global.t('validation.min', {min: '${min}'}),
            }
        });
        watch(
            () => i18n.global.locale.value,
            (newVal, oldVal) => {
                console.log(i18n.global.locale.value)
                setLocale({
                    mixed : {
                        required: i18n.global.t('validation.required'),
                    },
                    string: {
                        min: 'This must be at least ${min} characters.'
                    }
                });
            },
            {deep: true}
        )
    }
}