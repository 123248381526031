export const fr = {
    reset_password           : 'Réinitialisez votre mot de passe',
    reset_password_success   : 'Le mot de passe a été changé avec succès!',
    show_password            : 'Montre-moi le mot de passe',
    back_to_login            : 'Retour connexion',
    new_password             : 'Nouveau mot de passe',
    confirm_password         : 'Répéter le nouveau mot de passe',
    submit                   : "ENVOYER",
    loadingMessage           : 'S\'il vous plaît, attendez...',
    activate_account         : 'Activez votre compte',
    activate                 : 'Activer',
    activate_success         : 'Compte vérifié avec succès!',
    generate_verification_key: 'Générer une nouvelle clé',
    generate_success         : 'Nouvel e-mail de vérification envoyé! Veuillez vérifier à nouveau votre adresse e-mail!',
    confirm_email            : 'Confirmez votre changement d\'e-mail',
    confirm                  : 'Confirmer',
    confirm_email_success    : 'Votre adresse e-mail a été modifiée avec succès!',
    decline_email_change     : 'Refuser le changement d\'e-mail',
    decline_email_success    : 'Vous avez annulé la modification de l\'adresse e-mail',
    decline                  : 'Déclin',
    validation               : {
        required: 'Ce champ est requis!',
        min     : 'Cela doit comporter au moins {min} caractères!',
        oneOf   : 'Le champ Mot de passe ne correspond pas!'
    },
    apiSuccess               : {
        'RESET_PASSWORD_SUCCESS': 'Le mot de passe a été changé avec succès!'
    },
    apiErrors                : {
        'ERR_KEY_BLANK'                : "Votre token n\'est pas valide, veuillez demander à nouveau un nouveau token!",
        'ERR_PASSWORD_TOO_SHORT'       : 'Le mot de passe est trop court!',
        'ERR_PASSWORD_MISMATCH'        : 'Mot de passe et incompatibilité de mot de passe répétée!',
        'ERR_INVALID_BINDING_STRUCTURE': 'Oups, il y a un problème!',
        'ERR_NO_DATA_AVAILABLE'        : 'Votre token n\'est pas valide, veuillez demander à nouveau un nouveau token!',
        'ERR_KEY_INVALID'              : 'Votre token n\'est pas valide, veuillez demander à nouveau un nouveau token!',
        'ERR_INVALID_KEY'              : 'Votre token n\'est pas valide, veuillez demander à nouveau un nouveau token!',
        'ERR_BAD_REQUEST'              : 'Clé de vérification introuvable!',
        'ERR_DELETE'                   : 'La clé de vérification a été supprimée ou l\'utilisateur a déjà activé un compte!',
        'ERR_KEY_EXPIRED'              : 'Clé de vérification expirée!',
        'ERR_ACCOUNT_ACTIVATED'        : 'Compte activé!'
    }
}