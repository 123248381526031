<template>
    <div class="bg-image"></div>
    <main>
        <img class="w-40 mx-auto mb-10" :src="require('@/assets/horse_logo.png')" alt="Horse Republic">
        <router-view/>
    </main>
</template>

<script>
    import {computed, watch} from 'vue'
    import {useI18n}         from 'vue-i18n'
    import {useRoute}        from 'vue-router'

    export default {
        setup() {
            const route       = useRoute();
            const {t, locale} = useI18n();

            function changeLanguage(lang = 'fr') {
                if (lang !== 'en' && lang !== 'fr') return;
                locale.value = lang
            }

            watch(() => route.query, async query => {
                const {lang} = query;
                changeLanguage(lang)
            })

            return {
                t
            }
        }
    }
</script>