import {createApp} from 'vue'
import App         from './App.vue'
import router      from './router'
import store       from './store'
import i18n        from '@/plugins/i18n'
import yupLocale   from '@/plugins/yup'
import './assets/main.scss'


createApp(App)
.use(store)
.use(router)
.use(i18n)
.use(yupLocale)
.mount('#app')
