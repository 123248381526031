import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path     : '/',
        name     : 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path     : '/reset-password',
        name     : 'ResetPassword',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
    },
    {
        path     : '/verify-email',
        name     : 'VerifyEmail',
        component: () => import(/* webpackChunkName: "verify-email" */ '../views/VerifyEmail.vue')
    },
    {
        path     : '/change-email',
        name     : 'ChangeEmail',
        component: () => import(/* webpackChunkName: "change-email" */ '../views/ChangeEmail')
    },
    {
        path     : '/decline-email',
        name     : 'DeclineEmail',
        component: () => import(/* webpackChunkName: "decline-email" */ '../views/DeclineEmail')
    },
    {
        path: '/verifyEmail.html', redirect: to => {
            const {query}   = to;
            let parsedQuery = {};
            if (query.key) {
                const queryParamsValues = query.key.split('?email=');
                parsedQuery.key         = queryParamsValues[0];
                parsedQuery.email       = queryParamsValues[1];
                return {
                    path : '/verify-email',
                    query: parsedQuery,
                }
            } else {
                return {
                    path: '/verify-email',
                    query,
                }
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
