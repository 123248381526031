export const en = {
    reset_password           : 'Reset your password',
    reset_password_success   : 'Password has been successfully changed!',
    show_password            : 'Show me the password',
    back_to_login            : 'Back to login',
    new_password             : 'New Password',
    confirm_password         : 'Repeat new password',
    submit                   : "SUBMIT",
    loadingMessage           : 'Please wait...',
    activate_account         : 'Activate your account',
    activate                 : 'Activate',
    activate_success         : 'Account has been successfully verified!',
    generate_verification_key: 'Generate new token',
    generate_success         : 'New verification email sent! Please check your email again!',
    confirm_email            : 'Confirm your email change',
    confirm                  : 'Confirm',
    confirm_email_success    : 'Your email address has been successfully changed!',
    decline_email_change     : 'Decline email change',
    decline_email_success    : 'You canceled the email address change',
    decline                  : 'Decline',
    validation               : {
        required: 'This field is required!',
        min     : 'It must be at least {min} characters long!',
        oneOf   : 'Password field does not match!'
    },
    apiSuccess               : {
        'RESET_PASSWORD_SUCCESS': 'Password has been changed successfully!'
    },
    apiErrors                : {
        'ERR_KEY_BLANK'                : "Your token is not valid, please request a new token again!",
        'ERR_PASSWORD_TOO_SHORT'       : 'Password is too short!',
        'ERR_PASSWORD_MISMATCH'        : 'Repeated password and password mismatch!',
        'ERR_INVALID_BINDING_STRUCTURE': 'Oops, there is a problem!',
        'ERR_NO_DATA_AVAILABLE'        : 'Your token is not valid, please request a new token again!',
        'ERR_KEY_INVALID'              : 'Your token is not valid, please request a new token again!',
        'ERR_INVALID_KEY'              : 'Your token is not valid, please request a new token again!',
        'ERR_BAD_REQUEST'              : 'Verification key not found!',
        'ERR_DELETE'                   : 'The verification key has been deleted or the user has already activated an account!',
        'ERR_KEY_EXPIRED'              : 'Verification key expired!',
        'ERR_ACCOUNT_ACTIVATED'        : 'Account has been already activated!'
    }
}