import {createI18n} from 'vue-i18n'
import {fr}         from "@/lang/fr";
import {en}         from "@/lang/en";

const i18n = createI18n({
    legacy        : false,
    locale        : 'fr', // set locale
    fallbackLocale: 'fr',
    messages      : {
        fr, en
    }
})

export default i18n